$(function () {
    'use strict';

    initNav();
});


function initNav() {

    // Bootstrap off-canvas
    $('[data-toggle="offcanvas"]').on('click', function () {
        $(this).toggleClass('collapsed');
        var target = $(this).data('target');
        if (target) {
            $("body").toggleClass('nav-open');
            $(target).toggleClass('open');
        }
        else {
            $("body").toggleClass('nav-open');
            $('.offcanvas-collapse').toggleClass('open');
        }
    });
}
