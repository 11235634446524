(function ($) {
    var defaultOptions = {
        validClass: '',
        errorClass: 'is-invalid'
    };

    $.validator.setDefaults(defaultOptions);

    $.validator.unobtrusive.options = {
        errorClass: defaultOptions.errorClass,
        validClass: defaultOptions.validClass
    };

    // https://stackoverflow.com/a/52159728/1693918
    //$.validator.setDefaults({
    //    errorElement: 'span',
    //    errorPlacement: function (error, element) {
    //        error.addClass('invalid-feedback');
    //        element.closest('.form-group').append(error);
    //    },
    //    highlight: function (element, errorClass, validClass) {
    //        $(element).addClass('is-invalid');
    //    },
    //    unhighlight: function (element, errorClass, validClass) {
    //        $(element).removeClass('is-invalid');
    //    }
    //});

})(jQuery);
